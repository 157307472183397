import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { crossIcon, greenTickIcon, redCrossIcon, tickIcon } from "../../assets";
import OtaGradientButton from "../../components/OtaGradientButton";
import { backButton } from "../../assets/icons";
import RenderTranslatedText from "../../components/RenderTranslatedText";
import { otaQuizBackendBaseUrl } from "../../utils/auth";

export default function CheckQuizResponses() {
  const navigate = useNavigate();
  const [quizQuestions, setQuizQuestions] = useState([]);
  const [quiz, setQuiz] = useState(null);
  const [loading, setLoading] = useState(true);
  const { quizId } = useParams();
  const userId = localStorage.getItem("USER_ID");
  const {
    i18n: { language },
  } = useTranslation();

  useEffect(() => {
    const fetchQuizData = async () => {
      setLoading(true);
      try {
        const [quizResponse, questionsResponse] = await Promise.all([
          axios.get(`${otaQuizBackendBaseUrl.url}/${quizId}?lang=${language}`),
          axios.get(
            `${otaQuizBackendBaseUrl.url}/${quizId}/${userId}/responseCheck/all?lang=${language}`
          ),
        ]);

        setQuiz(quizResponse.data);

        const questions = questionsResponse.data.responses.map((response) => ({
          questionId: response.questionId,
          question: response.question,
          options: response.options.map((option) => ({
            ...option,
            isCorrect: option.optionId === response.correctOptionId,
            isSelected: option.optionId === response.optionId,
          })),
        }));
        setQuizQuestions(questions);
      } catch (error) {
        console.error("Error fetching quiz data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchQuizData();
  }, [language, quizId, userId]);

  if (loading || !quiz) {
    return <div className="otaPageBg h-screen flex justify-center items-center font-semibold">              <RenderTranslatedText i8nKey={"loading_text"} />
    </div>;
  }

  return (
    <div className="quiz-page otaPageBg min-h-screen max-w-[450px] mx-auto relative">
      {/* Header */}
      <div className="flex items-center p-4 bg-white shadow-md">
        <button onClick={() => navigate(-1)}>
          <img src={backButton} alt="Back" className="w-6" />
        </button>
        <h1 className="text-lg font-semibold text-gray-800 ml-4">
          {quiz.titleEn || quiz.titleJa}
        </h1>
      </div>

      {/* Quiz Cover */}
      <div className="relative w-full h-52">
        <img
          src={quiz.coverImg}
          alt="Quiz Background"
          className="w-full h-full object-cover"
        />
        <div className="absolute inset-0 bg-black bg-opacity-50 flex flex-col items-center justify-center text-white">
          <h1 className="text-xl font-bold">
            {quizQuestions.length} Questions
          </h1>
        </div>
      </div>

      {/* Questions */}
      <div className="p-4 pb-20">
        {quizQuestions.map((question, index) => (
          <div key={question.questionId} className="mt-6">
            <h2 className="font-bold mb-4">
              {index + 1}. {question.question}
            </h2>
            {question.options.map((option, optIndex) => (
              <div
                key={option.optionId}
                className={`w-full flex justify-between items-center px-4 py-3 rounded-2xl my-3 border-2 ${
                  option.isCorrect && option.isSelected
                    ? "bg-green-100 border-green-500"
                    : option.isSelected
                    ? "bg-red-100 border-red-500"
                    : "bg-white border-gray-300"
                }`}
              >
                <p>
                  <span className="font-semibold">
                    {String.fromCharCode(65 + optIndex)}.{" "}
                  </span>
                  {option.optionText}
                </p>
                {option.isCorrect && option.isSelected && <img src={greenTickIcon} alt="Correct" />}
                {option.isSelected && !option.isCorrect && (
                  <img src={redCrossIcon} alt="Wrong" />
                )}
              </div>
            ))}
          </div>
        ))}
      </div>

      {/* Navigation Buttons */}
      <div className="fixed bottom-0 w-full bg-white p-3.5 border-t-2 border-gray-300">
        <OtaGradientButton
          onClick={() => navigate(`/quiz/${quizId}/ranking`)}
          className="rounded-md w-full p-3 px-3.5 text-white"
        >
          <RenderTranslatedText i8nKey={"quiz_page_check_ranking"} />
        </OtaGradientButton>
      </div>
    </div>
  );
}
