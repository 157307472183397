import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import fetchApi from "../../lib/strapi";
import { homeCategoriesConfig } from "./constants";
import RenderTranslatedText from "../../components/RenderTranslatedText";
import {
  // commentIcon,
  eyes,
  heartFilled,
  heartOutline,
} from "../../assets/icons";
import useResetScroll from "../../utils/useResetScroll";
import { useTranslation } from "react-i18next";

const Home = () => {
  useResetScroll();
  const {
    i18n: { language },
  } = useTranslation();
  const [articles, setArticles] = useState({});
  const [loading, setLoading] = useState(true);
  const [selectedCategory, setSelectedCategory] = useState("anime/manga");
  const [stats, setStats] = useState([]);
  const navigate = useNavigate();
  const userId = localStorage.getItem("USER_ID");

  const handleArticleSelect = (categoryId, documentId) => {
    const formattedCategoryId = categoryId.replace(/\//g, "_");
    navigate(`/home/${formattedCategoryId}/${documentId}`);
  };

  const fetchStats = async () => {
    try {
      const apiResponse = await fetch(
        "https://api.be-native.life/api/blog/stats/all",
        {
          method: "GET",
        }
      );
      const apiData = await apiResponse.json();
      setStats(apiData.data || []);
    } catch (error) {
      error("Error fetching stats:", error);
    }
  };

  const fetchArticles = async (category) => {
    try {
      setLoading(true);
      const articlesData = await fetchApi({
        endpoint: "articles",
        wrappedByKey: "data",
        wrappedByList: true,
        query: {
          "populate[0]": "Cover",
          "pagination[pageSize]": "100",
          "sort[0]": "published_date:desc",
          "filters[Category][$eqi]": category,
          locale: language === "ja" ? "ja-JP" : "en",
        },
      });

      // Map stats to articles
      const updatedArticles = articlesData.map((article) => {
        const matchedStats = stats.find(
          (stat) => stat.blog_id === article.documentId
        );
        return {
          ...article,
          num_views: matchedStats?.num_views || 0,
          num_likes: matchedStats?.num_likes || 0,
          num_comments: matchedStats?.num_comments || 0,
        };
      });

      setArticles(updatedArticles);
    } catch (error) {
      error("Error fetching articles:", error);
    } finally {
      setLoading(false);
    }
  };

  const toggleLike = async (blogId) => {
    try {
      setArticles((prevArticles) =>
        prevArticles.map((article) =>
          article.documentId === blogId
            ? {
                ...article,
                liked: !article.liked,
                num_likes: article.liked
                  ? article.num_likes - 1
                  : article.num_likes + 1,
              }
            : article
        )
      );

      const queryParams = new URLSearchParams({
        blog_id: blogId,
        user_id: userId,
      }).toString();

      const response = await fetch(
        `https://api.be-native.life/api/blog/likes?${queryParams}`,
        {
          method: "PUT",
        }
      );

      if (!response.ok) {
        throw new Error(`API Error: ${response.statusText}`);
      }
    } catch (error) {
      error("Error toggling like:", error);
    }
  };

  useEffect(() => {
    fetchStats();
  }, []);

  useEffect(() => {
    if (stats.length > 0) {
      fetchArticles(selectedCategory);
    }
  }, [selectedCategory, stats, language]);

  return (
    <div className="max-w-[450px] mx-auto">
      <div className="w-full p-5 h-[60px] flex items-center bg-white justify-between">
        <p className="font-semibold text-2xl gradient-text">Ōta</p>
      </div>
      <div className="p-5 otaPageBg min-h-[80vh]">
        <div className="grid grid-cols-4 gap-10 mt-2 mb-6 px-5">
          {homeCategoriesConfig.map(
            ({ value, labelKey, icon, activeIcon, color }) => {
              const isActive =
                selectedCategory.replace(/\//g, "_") ===
                value.replace(/\//g, "_");
              return (
                <div
                  key={value}
                  className="flex flex-col items-center gap-1 cursor-pointer"
                  onClick={() => setSelectedCategory(value)}
                >
                  <img
                    className="w-full"
                    src={isActive ? activeIcon : icon}
                    alt={value}
                  />

                  <p
                    className="text-[14px] font-semibold"
                    style={{ color: color }}
                  >
                    <RenderTranslatedText i8nKey={labelKey} />
                  </p>
                </div>
              );
            }
          )}
        </div>

        {loading ? (
          <div className="min-h-[500px] flex items-center justify-center">
            <p className="text-lg font-bold">
              <RenderTranslatedText i8nKey="loading_text" />
            </p>
          </div>
        ) : (
          <div>
            {articles.map((article, index) => {
              const {
                documentId,
                Title,
                published_date,
                Cover,
                hashtags_comma_seperated,
                num_comments,
                num_likes,
                num_views,
                liked,
              } = article;
              const hashtags = hashtags_comma_seperated
                ? hashtags_comma_seperated
                    .split(",")
                    .map((tag) => `#${tag.trim()?.replace("#", "")}`)
                : [];

              return (
                <div key={documentId}>
                  <div
                    onClick={() =>
                      handleArticleSelect(selectedCategory, documentId)
                    }
                    className="flex justify-between items-start mb-2 gap-2"
                  >
                    <div>
                      <p className="text-[#818897] text-sm">{published_date}</p>
                      <p className="mb-2 line-clamp-3">{Title}</p>
                      <p className="font-semibold">{hashtags.join(" ")}</p>
                    </div>
                    <img
                      className="min-w-28 max-w-28 h-20 rounded-xl object-cover"
                      src={Cover.url}
                      alt={Title}
                    />
                  </div>
                  <div className="flex space-x-2 mt-2">
                    <div className="flex flex-row items-center gap-1 border-2 p-1.5 rounded-2xl bg-white">
                      <img src={eyes} className="w-[18px] object-contain" />
                      <p className="text-[12px] text-[#424242]">{num_views}</p>
                    </div>
                    {liked ? (
                      <div
                        className="flex flex-row items-center gap-1 border-2 p-1.5 rounded-2xl"
                        onClick={() => toggleLike(documentId)}
                      >
                        <img
                          src={heartFilled}
                          className="w-[18px] object-contain"
                        />
                        <p className="text-[12px] text-[#424242]">
                          {num_likes}
                        </p>
                      </div>
                    ) : (
                      <div
                        className="flex flex-row items-center gap-1 border-2 p-1.5 rounded-2xl bg-white"
                        onClick={() => toggleLike(documentId)}
                      >
                        <img
                          src={heartOutline}
                          className="w-[18px] object-contain"
                        />
                        <p className="text-[12px] text-[#424242]">
                          {num_likes}
                        </p>
                      </div>
                    )}

                    {/* <div className="flex flex-row items-center gap-1 border-2 p-1.5 rounded-2xl bg-white">
                      <img
                        src={commentIcon}
                        className="w-[18px] object-contain"
                      />
                      <p className="text-[12px] text-[#424242]">
                        {num_comments}
                      </p>
                    </div> */}
                  </div>
                  {index < articles.length - 1 && <hr className="my-4" />}
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default Home;
