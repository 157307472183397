import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { otaQuizBackendBaseUrl } from "../../utils/auth";
import RenderTranslatedText from "../../components/RenderTranslatedText";
import { backButton } from "../../assets/icons";
import { useTranslation } from "react-i18next";

export default function QuizCategory() {
  const navigate = useNavigate();
  const { category } = useParams(); 
  const [quizData, setQuizData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [myQuizData, setMyQuizData] = useState([]);
  const userId = localStorage.getItem("USER_ID");
 const {
    t,
    i18n: { language },
  } = useTranslation();
  useEffect(() => {
    const fetchQuizzes = async () => {
      try {
        const lang = language; // Get current language (either 'en' or 'ja')
        const response = await axios.get(`${otaQuizBackendBaseUrl.url}?lang=${lang}`);
        setQuizData(response.data);
      } catch (error) {
        console.error("Error fetching quizzes:", error);
      } finally {
        setLoading(false);
      }
    };
  
    const fetchUserQuizzes = async () => {
      const userId = localStorage.getItem("USER_ID");
      try {
        const response = await axios.get(`${otaQuizBackendBaseUrl.url}/user-quiz/${userId}?lang=${language}`);
        setMyQuizData(response.data); // Store user-associated quiz data
      } catch (error) {
        console.error("Error fetching user quizzes:", error);
      }
    };
  
    fetchQuizzes();
    fetchUserQuizzes();
  }, [language]);

  const handleQuizNavigation = async (quizId) => {
    const userId = localStorage.getItem("USER_ID");
    try {
      const response = await axios.get(
        `${otaQuizBackendBaseUrl.url}/${quizId}/${userId}/response-check`
      );

      if (response.data.exists) {
        navigate(`/quiz/${quizId}/response`);
      } else {
        navigate(`/quiz/${quizId}`);
      }
    } catch (error) {
      console.error("Error checking response:", error);
    }
  };

  return (
    <div className="otaPageBg max-w-[450px] min-h-screen mx-auto">
      
      <div className="flex items-center p-4 bg-white shadow-md">
        <button onClick={() => navigate(-1)}>
          <img src={backButton} alt="Back" className="w-6" />
        </button>
        <h1 className="text-lg font-semibold text-gray-800 ml-4">
        <RenderTranslatedText i8nKey={`quiz_page_${category}`} />
        </h1>
      </div><div className="p-5 max-w-[450px] mx-auto">
        {loading ? (
          <p className="h-[600px] flex text-center justify-center items-center font-semibold text-lg">
            <RenderTranslatedText i8nKey="loading_text" />
          </p>
        ) : error ? (
          <p className="h-[200px] flex text-center justify-center items-center font-semibold text-lg">
            {error}
          </p>
        ) : (
          <div className="grid grid-cols-2 gap-4 w-full">
            {category === "my_quiz" ? (
  myQuizData.map((quiz) => (
    <div
      key={quiz.quizId}
      onClick={() => handleQuizNavigation(quiz.quizId)}
      className="relative w-full h-[250px] rounded-lg overflow-hidden shadow-lg cursor-pointer"
    >
      <img
        src={quiz.coverImg}
        alt={quiz.quizId}
        className="w-full h-full object-cover"
      />
      <div className="absolute bottom-0 left-0 right-0 h-[200px] bg-gradient-to-t from-black to-transparent"></div>
      <p className="absolute bottom-2 left-2 text-white text-sm">
        {quiz.titleEn || quiz.titleJa}
      </p>
    </div>
  ))
) : (
  quizData.map((quiz) => (
    <div
      key={quiz.quizId}
      onClick={() => handleQuizNavigation(quiz.quizId)}
      className="relative w-full h-[250px] rounded-lg overflow-hidden shadow-lg cursor-pointer"
    >
      <img
        src={quiz.coverImg}
        alt={quiz.quizId}
        className="w-full h-full object-cover"
      />
      <div className="absolute bottom-0 left-0 right-0 h-[200px] bg-gradient-to-t from-black to-transparent"></div>
      <p className="absolute bottom-2 left-2 text-white text-sm">
        {quiz.titleEn || quiz.titleJa}
      </p>
    </div>
  ))
)}

          </div>
        )}
      </div>
    </div>
  );
}
