import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import OtaGradientOutlineButton from "../../components/OtaGradientOutlineButton";
import RenderTranslatedText from "../../components/RenderTranslatedText";
import { TestQuizCategoryImg3 } from "../../assets";
import { backButton } from "../../assets/icons";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { otaQuizBackendBaseUrl } from "../../utils/auth";

export default function QuizResponse() {
  const navigate = useNavigate();
  const [quizData, setQuizData] = useState(null); // Set initial state to null for better handling of loading
  const {
    i18n: { language },
  } = useTranslation();
  const { quizId } = useParams();
  const { userId } = localStorage.getItem("USER_ID");

  const handlePlayAgain =  () => {
        navigate(`/quiz/${quizId}/play`);
      
  };

  useEffect(() => {
    const fetchQuiz = async () => {
      try {
        const lang = language; // Get current language (either 'en' or 'ja')
        const response = await axios.get(
          `${otaQuizBackendBaseUrl.url}/${quizId}?lang=${lang}`
        );
        setQuizData(response.data);
      } catch (error) {
        console.error("Error fetching quizzes:", error);
      }
    };

    fetchQuiz();
  }, [language, quizId]);

  if (!quizData) {
    return (
        <div className="otaPageBg h-screen flex justify-center items-center font-semibold">           
           <RenderTranslatedText i8nKey={"loading_text"} />
            </div>
    );
  }

  return (
    <div className="relative w-full h-[88vh] pb-20 bg-black flex flex-col">
      {/* Background Image */}
      <div className="absolute w-full h-full">
        <img
          src={quizData.coverImg}
          alt="Anime Background"
          className="w-full h-full object-cover"
        />
        {/* Gradient Overlay */}
        <div className="absolute inset-0 bg-gradient-to-t from-black to-transparent"></div>
      </div>

      {/* Content */}
      <div className="relative flex flex-col justify-end items-center h-full px-5 text-center">
        {/* Back Button */}
        <div
          className="absolute top-5 left-5 bg-white bg-opacity-80 p-2 rounded-full shadow-md"
          onClick={() => navigate(-1)}
        >
          <img className="w-[24px]" src={backButton} alt="Back" />
        </div>
        <h1 className="text-white text-3xl font-bold mb-4">
          {quizData.titleEn || quizData.titleJa}
        </h1>{" "}
        <OtaGradientOutlineButton
          onClick={() => navigate(`/quiz/${quizId}/check-response`)}
          className="relative z-10 mb-4"
        >
          <p className="font-semibold px-10">Check Responses</p>
        </OtaGradientOutlineButton>
        <OtaGradientOutlineButton
          onClick={() => handlePlayAgain()}
          className="relative z-10 mb-4"
        >
          <p className="font-semibold px-10">
            <RenderTranslatedText i8nKey={"quiz_page_play_again_cta"} />
          </p>
        </OtaGradientOutlineButton>
      </div>
    </div>
  );
}
