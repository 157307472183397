import React from "react";
import { useNavigate } from "react-router-dom";
import { backButton } from "../../assets/icons";
import { useTranslation } from "react-i18next";
import OtaGradientButton from "../../components/OtaGradientButton";
import OtaGradientOutlineButton from "../../components/OtaGradientOutlineButton";
import { TestQuizCategoryImg } from "../../assets";

const QuizTop = () => {
  const navigate = useNavigate();
  const mainColor = localStorage.getItem("USER_COLOR");
  const userName = localStorage.getItem("USER_NAME");
  const userType = localStorage.getItem("USER_NATIVE_TYPE");
  const userTagline = localStorage.getItem("USER_CHARA_TAGLINE");

  const { t } = useTranslation();

  return (
    <div className="quiz-page otaPageBg min-h-[190vw] max-w-[450px] mx-auto relative">
      {/* Header */}
      <div className="flex items-center p-4 bg-white shadow-md">
        <button onClick={() => navigate(-1)}>
          <img src={backButton} alt="Back" className="w-6" />
        </button>
        <h1 className="text-lg font-semibold text-gray-800 ml-4 capitalize">
          {userName}
        </h1>
      </div>

      {/* Top Card */}
      <div
        className="w-[95%] mx-auto flex flex-col gap-4 mt-4 bg-white rounded-[20px] min-h-[460px] overflow-hidden relative"
        style={{ boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)" }}
      >
        {/* Design Elements Start */}
        <div className="absolute right-5 top-10 grid grid-cols-4 gap-2">
          {Array(24)
            .fill("")
            .map(() => {
              return (
                <div
                  className="z-0 h-[6px] w-[6px] rounded-full opacity-70"
                  style={{ background: mainColor }}
                />
              );
            })}
        </div>

        <div className="absolute left-5 bottom-28 grid grid-cols-6 gap-2">
          {Array(24)
            .fill("")
            .map(() => {
              return (
                <div
                  className="z-0 h-[6px] w-[6px] rounded-full opacity-70"
                  style={{ background: mainColor }}
                />
              );
            })}
        </div>

        <div
          className="w-[75%] flex items-center justify-between absolute top-[110px] left-[50%]"
          style={{ transform: "translate(-45%)" }}
        >
          <div
            className="w-7 h-7 rounded-full opacity-80"
            style={{ background: mainColor }}
          />
          <div
            className="w-16 h-16 rounded-full opacity-30"
            style={{ background: mainColor }}
          />
        </div>

        <div
          className="w-5 h-5 rounded-full absolute top-[180px] left-8"
          style={{ background: mainColor }}
        />

        <div
          className="w-[75%] flex items-center justify-between absolute top-[230px] left-[50%]"
          style={{ transform: "translate(-50%)" }}
        >
          <div
            className="w-12 h-12 rounded-full opacity-50"
            style={{ background: mainColor }}
          />
          <div
            className="w-7 h-7 rounded-full opacity-80"
            style={{ background: mainColor }}
          />
        </div>
        {/* Design Elements End */}

        <div
          className="w-full flex flex-col items-center rounded-xl gap-5 z-10"
          style={{ borderTop: `8px solid ${mainColor} ` }}
        >
          <h1
            className="text-[18px] text-black font-bold text-center mt-10 w-[70%] mx-auto"
            dangerouslySetInnerHTML={{
              __html: t("native_card_title", { userName }),
            }}
          />
          <div className="flex flex-col gap-1 mt-1 w-[50%] mx-auto">
            <p
              style={{ color: mainColor }}
              className="text-[14px] text-center font-semibold"
            >
              {userTagline}
            </p>
            <p
              style={{ color: mainColor }}
              className="text-[42px] text-center font-bold"
            >
              {userType}
            </p>
          </div>
          {userType && (
            <img
              alt=""
              src={`https://api.be-native.life/api/friends/charimg/${userType}`}
              style={{
                zIndex: 1,
                width: "200px",
                height: "200px",
                marginTop: "-24px",
              }}
            />
          )}

          <div className="flex flex-col gap-1 pt-4 bg-white z-10 ">
            <p className="text-[14px] text-black font-semibold text-center w-[90%] m-auto mb-5">
              "Master of Reality: Unleashing the Hidden Power of the Diligent
              Manager with the Element of Wood"
            </p>
          </div>
          <div
            style={{ background: mainColor, opacity: 0.3 }}
            className="w-[660px] h-[708px] rounded-[860px] absolute bottom-[-546px] z-0"
          />
        </div>
      </div>

      <div className="mx-3 my-4">
        <OtaGradientButton className="py-3 rounded-xl">
          Compatibility Check
        </OtaGradientButton>
      </div>

      {/* <div className="flex justify-center items-center gap-4 mx-3 mb-4 border-2 rounded-xl p-4 bg-white">
        <div className="w-full text-right">
          <p className="font-semibold text-xl mb-2">34K</p>
          <p className="mb-4">Followers</p>
          <OtaGradientButton className="w-1/2 py-3 rounded-lg">
            Follow{" "}
          </OtaGradientButton>
        </div>
        <div className="w-full">
          <p className="font-semibold text-xl mb-2">580</p>
          <p className="mb-4">Following</p>
          <OtaGradientOutlineButton className="w-full">
            <p className="font-semibold px-6">Message</p>
          </OtaGradientOutlineButton>
        </div>
      </div> */}

      <div>
        <h1 className="font-bold text-lg mx-3 mb-4">
          Participating Communities
        </h1>

        <div className="grid grid-cols-3 gap-y-4 mx-3 mb-8">
          <div className="max-w-[100px] min-w-[100px] flex flex-col mx-auto gap-1 items-center">
            <img
              src={TestQuizCategoryImg}
              className="aspect-square object-cover object-center rounded-3xl w-full overflow-hidden"
              style={{ border: "1px solid #D0D5DD" }}
            />
            <p className="otaLight text-[12px]">nickname</p>
            <OtaGradientOutlineButton className="w-20">
              <p className="font-semibold px-1 ">Join</p>
            </OtaGradientOutlineButton>
          </div>
          <div className="max-w-[100px] min-w-[100px] flex flex-col mx-auto gap-1 items-center">
            <img
              src={TestQuizCategoryImg}
              className="aspect-square object-cover object-center rounded-3xl w-full overflow-hidden"
              style={{ border: "1px solid #D0D5DD" }}
            />
            <p className="otaLight text-[12px]">nickname</p>
            <OtaGradientOutlineButton className="w-20">
              <p className="font-semibold px-1 ">Join</p>
            </OtaGradientOutlineButton>
          </div>
          <div className="max-w-[100px] min-w-[100px] flex flex-col mx-auto gap-1 items-center">
            <img
              src={TestQuizCategoryImg}
              className="aspect-square object-cover object-center rounded-3xl w-full overflow-hidden"
              style={{ border: "1px solid #D0D5DD" }}
            />
            <p className="otaLight text-[12px]">nickname</p>
            <OtaGradientOutlineButton className="w-20">
              <p className="font-semibold px-1 ">Join</p>
            </OtaGradientOutlineButton>
          </div>
          <div className="max-w-[100px] min-w-[100px] flex flex-col mx-auto gap-1 items-center">
            <img
              src={TestQuizCategoryImg}
              className="aspect-square object-cover object-center rounded-3xl w-full overflow-hidden"
              style={{ border: "1px solid #D0D5DD" }}
            />
            <p className="otaLight text-[12px]">nickname</p>
            <OtaGradientOutlineButton className="w-20">
              <p className="font-semibold px-1 ">Join</p>
            </OtaGradientOutlineButton>
          </div>
          <div className="max-w-[100px] min-w-[100px] flex flex-col mx-auto gap-1 items-center">
            <img
              src={TestQuizCategoryImg}
              className="aspect-square object-cover object-center rounded-3xl w-full overflow-hidden"
              style={{ border: "1px solid #D0D5DD" }}
            />
            <p className="otaLight text-[12px]">nickname</p>
            <OtaGradientOutlineButton className="w-20">
              <p className="font-semibold px-1 ">Join</p>
            </OtaGradientOutlineButton>
          </div>
          <div className="max-w-[100px] min-w-[100px] flex flex-col mx-auto gap-1 items-center">
            <img
              src={TestQuizCategoryImg}
              className="aspect-square object-cover object-center rounded-3xl w-full overflow-hidden"
              style={{ border: "1px solid #D0D5DD" }}
            />
            <p className="otaLight text-[12px]">nickname</p>
            <OtaGradientOutlineButton className="w-20">
              <p className="font-semibold px-1 ">Join</p>
            </OtaGradientOutlineButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuizTop;
