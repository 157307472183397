import React, { useEffect, useState } from "react";
import AnimeSelection from "../community/components/AnimeSelection";
import SelectedAnime from "../community/components/SelectedAnime";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import RenderTranslatedText from "../../components/RenderTranslatedText";
import { getFileUrl } from "../../components/ImageUpload/constants";
import {
  backButton,
  heartFilled,
  heartOutline,
  commentIcon,
} from "../../assets/icons";
import { noChar, noThreads } from "../../assets";
import OtaGradientOutlineButton from "../../components/OtaGradientOutlineButton";
import OtaGradientButton from "../../components/OtaGradientButton";
import { useTranslation } from "react-i18next";
import useResetScroll from "../../utils/useResetScroll";
import { community } from "../../assets/navicons";
import { communityNavConfig } from "../community/constants";

export const singleCommunityNavConfig = [
  {
    path: "/community/:communityId", // Updated for dynamic communityId
    name: "top_nav_community",
  },
  {
    path: "/:communityId/quiz", // Updated for dynamic communityId
    name: "top_nav_participated_quiz",
  },
];

function CommunitySingle() {
  useResetScroll();
  const { key } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();
  const [communityData, setCommunityData] = useState(null);
  const [error, setError] = useState(null);
  const [currentThread, setCurrentThread] = useState([]);
  const [isLiking, setIsLiking] = useState(false);
  const [postMessage, setPostMessage] = useState("");
  const [triggerAPI, setTriggerAPI] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isPosting, setIsPosting] = useState(false);
  const [characters, setCharacters] = useState([]);
  const [isActive, setIsActive] = useState("top_nav_community");

  useEffect(() => {
    if (key) {
      fetchCommunityData();
      fetchCharacters();
    }
    window.scrollTo(0, 0);
    setCurrentThread(null);
    setPostMessage("");
  }, [key, location]);

  useEffect(() => {
    const fetchData = async () => {
      if (!key) return;
      try {
        const threadRes = await fetch(
          `https://api.be-native.life/api/travel/favthreads`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        const threadData = await threadRes.json();
        const filteredThreads = threadData.filter(
          (thread) => thread.parent_id === key
        );
        setCurrentThread(filteredThreads);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [key, location, triggerAPI]);

  const handleThreadClick = (thread_id) => {
    navigate(`/community/thread/${key}/${thread_id}`); //key is community_id
  };

  const handleLikeMessage = async (messageId) => {
    if (isLiking) return;
    setIsLiking(true);
    try {
      await fetch(
        `https://api.be-native.life/api/travel/favthread/messages/${messageId}/likes?user_id=${localStorage.getItem(
          "USER_ID"
        )}`,
        {
          method: "POST",
        }
      );
      setTriggerAPI((p) => !p);
    } catch (_) {
    } finally {
      setIsLiking(false);
    }
  };

  const fetchCommunityData = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch(
        `https://api.be-native.life/api/travel/favcard/${key}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (!response.ok) {
        throw new Error("Failed to fetch community data");
      }

      const data = await response.json();
      setCommunityData(data);
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchCharacters = async () => {
    try {
      const response = await fetch(
        `https://api.be-native.life/api/character/${key}/characters`,
        {
          method: "GET",
          headers: {
            accept: "application/json",
          },
        }
      );
      if (!response.ok) {
        throw new Error("Failed to fetch characters");
      }
      const data = await response.json();
      setCharacters(data);
    } catch (error) {
      console.error("Error fetching characters:", error);
    }
  };

  function formatDate(dateString, t = () => {}) {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();

    return `${t("posted_on_text")} ${day}/${month}/${year}`;
  }

  const handleCreateCharacter = () => {
    navigate(`/characters/create?communityId=${communityData.favcard_id}`);
  };

  const handleCreateThread = () => {
    navigate(`/thread-create/${communityData.favcard_id}`);
  };

  const handleAnimeSelect = (anime) => {
    navigate(`/community/${anime}`);
  };

  const handleNavClick = (item) => {
    const communityId = key; // Assuming `key` is the communityId from useParams
    const updatedPath = item.path.replace(":communityId", communityId);
    navigate(updatedPath);
    setIsActive(item.name);
  };

  return isLoading ? (
    <div className="min-h-[100svh] w-full flex flex-col items-center justify-center gap-2">
      <p className="text-[24px] font-bold text-neutral-800">
        <RenderTranslatedText i8nKey={"loading_text"} />
      </p>
    </div>
  ) : (
    <div>
      <div className="p-5 w-full h-[60px] gap-2 bg-white max-w-[450px] mx-auto flex items-center border-b-2 border-gray-300">
        <div onClick={() => navigate(-1)}>
          <img className="w-[24px]" src={backButton} alt="Back" />
        </div>
        <p className="font-semibold text-[17px] text-[#414853]">
          {communityData ? (
            <RenderTranslatedText i8nKey={communityData.title} />
          ) : (
            <RenderTranslatedText i8nKey={"loading_text"} />
          )}
        </p>
      </div>
      <div className="flex items-center justify-between border-b-2">
        {singleCommunityNavConfig?.map((item) => (
          <p
            key={item.path}
            onClick={() => handleNavClick(item)}
            className={`cursor-pointer w-full text-center py-2 ${
              isActive === item.name
                ? "gradient-text font-bold border-b-4 border-gradient"
                : "text-[#667085]"
            }`}
          >
            <RenderTranslatedText i8nKey={item.name} />
          </p>
        ))}
      </div>
      <div className=" otaPageBg max-w-[450px] min-h-[100svh] mx-auto">
        <div className="p-5 mx-auto ">
          <div className="flex items-center space-x-4">
            {communityData.imageId && (
              <div className="relative">
                <img
                  src={getFileUrl(communityData.imageId)}
                  className="h-[80px] max-h-[80px] object-cover object-center rounded-xl w-[160px] overflow-hidden"
                  style={{ border: "1px solid #D0D5DD" }}
                  alt={communityData.title || "Community"}
                />
                <div className=" absolute translate-x-[-50%] left-[50%] bottom-[-14px] flex flex-row items-center gap-0.5 border-2 px-2 py-[0.5] rounded-3xl bg-white w-max justify-center">
                  <img
                    src={community}
                    className="min-w-[18px] object-contain scale-[0.6]"
                  />
                  <p className="text-[12px] text-[#424242]">
                    {communityData?.num_users_interacted}
                  </p>
                </div>
              </div>
            )}
            <div>
              <h2 className="font-bold text-lg">{communityData.title}</h2>
              <p className="text-[12px] text-[#555E6C]">
                <RenderTranslatedText
                  i8nKey={"community_selected_fav_card_subtitle"}
                />
              </p>
            </div>
          </div>
        </div>
        <hr className="border-gray-300 mt-2" />

        <div className="p-5">
          <div className="flex justify-between items-center">
            <p className="text-[14px] font-semibold mb-4">
              <RenderTranslatedText
                i8nKey={
                  "community_create_page_check_compatibility_with_character_title"
                }
              />
            </p>
            {/* {characters.length > 0 && (
              <p
                onClick={() => navigate(`/characters`)}
                className="text-[14px] font-semibold mb-4 gradient-text"
              >
                <RenderTranslatedText
                  i8nKey={
                    "community_create_page_check_compatibility_with_character_See_All"
                  }
                />
              </p>
            )} */}
          </div>
          {characters.length > 0 ? (
            <div className="grid grid-cols-4 gap-4">
              {characters.map((character) => (
                <div
                  onClick={() => navigate(`/characters/${character.char_id}`)}
                  key={character.char_id}
                  className="w-full h-min rounded-lg"
                >
                  <img
                    src={getFileUrl(character.picture)}
                    alt={character.name}
                    className="w-full aspect-square object-cover rounded-lg"
                    style={{ border: "1px solid #D0D5DD" }}
                  />
                  <p className="text-center text-xs mt-2">
                    {character.nickname}
                  </p>
                </div>
              ))}
            </div>
          ) : (
            <div className="flex flex-col justify-center items-center ">
              <img src={noChar} alt="bubbleSvg" />
              <p className="text-[14px] text-[#344054] ">
                <RenderTranslatedText
                  i8nKey={"community_create_page_no_character_added_subtitle"}
                />
              </p>
            </div>
          )}
          <div className="w-full items-center justify-center flex">
            <OtaGradientOutlineButton
              className="px-5 rounded-xl mt-4"
              onClick={handleCreateCharacter}
            >
              <p className="font-semibold">
                <RenderTranslatedText
                  i8nKey={"community_create_page_create_new_community_cta"}
                />
              </p>
            </OtaGradientOutlineButton>
          </div>
        </div>

        <hr className="border-gray-300 my-4" />
        <div className="pb-24">
          {currentThread && currentThread.length > 0 && (
            <h1 className="font-semibold text-lg px-5">
              <RenderTranslatedText
                i8nKey={"community_single_page_thread_title"}
              />
            </h1>
          )}
          {currentThread && currentThread.length > 0 ? (
            currentThread.map(
              ({
                param1,
                param4,
                title,
                param2,
                num_messages,
                thread_id,
                num_likes,
                created_at,
                creator_user_id,
              }) => {
                return (
                  <>
                    <div
                      className="w-full grid rounded-lg gap-3 rounded-bl-none p-5 pb-0"
                      style={{ gridTemplateColumns: "1fr 7fr" }}
                      onClick={() => handleThreadClick(thread_id)}
                    >
                      <div
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          navigate(`/user-profile/${creator_user_id}`);
                        }}
                      >
                        <img
                          className="aspect-square object-contain"
                          src={`https://api.be-native.life/api/friends/charimg/${param4}`}
                        />
                      </div>
                      <div>
                        <p className="font-semibold text-[14px]">{param2}</p>
                        <p className="text-[10px] text-[#808691] my-1">
                          {" "}
                          {formatDate(created_at, t)}
                        </p>
                        <p className="font-medium text-[12px]">{title}</p>
                        <p className="text-[#808691] text-[12px]">{param1}</p>
                        <div className=" w-min py-1.5 rounded-b-lg flex items-center gap-2">
                          <div className="flex flex-row items-center gap-1 border-2 p-1.5 rounded-xl bg-white">
                            <img
                              src={heartOutline}
                              className="min-w-[18px] object-contain"
                            />
                            <p className="text-[12px] text-[#424242]">
                              {num_likes}
                            </p>
                          </div>
                          <div className="flex flex-row items-center gap-1 border-2 p-1.5 rounded-xl bg-white">
                            <img
                              src={commentIcon}
                              className="min-w-[18px] object-contain"
                            />
                            <p className="text-[12px] text-[#424242]">
                              {num_messages}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="fixed w-max bottom-24 left-[50%] translate-x-[-50%]">
                      <OtaGradientButton
                        className="px-5 py-2 rounded-xl"
                        onClick={handleCreateThread}
                      >
                        <p className="font-semibold">
                          <RenderTranslatedText
                            i8nKey={
                              "community_single_page_create_new_thread_cta"
                            }
                          />
                        </p>
                      </OtaGradientButton>
                    </div>
                  </>
                );
              }
            )
          ) : (
            <div className="flex flex-col justify-center items-center my-4">
              <img src={noThreads} alt="noThreadsSvg" />
              <p className="text-[14px] text-center font-semibold">
                <RenderTranslatedText
                  i8nKey={"community_create_page_no_threads_added_title"}
                />
              </p>
              <p
                className="text-[14px] text-center text-[#344054]"
                dangerouslySetInnerHTML={{
                  __html: t("community_create_page_no_threads_added_subtitle"),
                }}
              />
              <OtaGradientButton
                className="px-5 py-2 rounded-xl mt-4"
                onClick={handleCreateThread}
              >
                <p className="font-semibold">
                  <RenderTranslatedText
                    i8nKey={"community_create_page_create_thread_cta"}
                  />
                </p>
              </OtaGradientButton>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default CommunitySingle;
