import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import fetchApi from "../../lib/strapi";
import RenderTranslatedText from "../../components/RenderTranslatedText";
import {
  backButton,
  // commentIcon,
  eyes,
  heartOutline,
  heartFilled,
} from "../../assets/icons";
import useResetScroll from "../../utils/useResetScroll";
import { useTranslation } from "react-i18next";
import ShareSection from "../../components/ShareSection";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import CommentSection from "../../components/CommentSection";

const HomeCategoryArticle = () => {
  useResetScroll();
  const {
    i18n: { language },
    t,
  } = useTranslation();
  const { documentId } = useParams();
  const [article, setArticle] = useState(null);
  const [loading, setLoading] = useState(true);
  const [stats, setStats] = useState({
    num_likes: 0,
    num_views: 0,
    num_comments: 0,
  });
  const navigate = useNavigate();

  const toggleLike = async () => {
    try {
      const queryParams = new URLSearchParams({
        blog_id: documentId,
        user_id: localStorage.getItem("USER_ID"),
      }).toString();

      setArticle((prevArticle) => ({
        ...prevArticle,
        liked: !prevArticle.liked,
        num_likes: prevArticle.liked
          ? prevArticle.num_likes - 1
          : prevArticle.num_likes + 1,
      }));

      const response = await fetch(
        `https://api.be-native.life/api/blog/likes?${queryParams}`,
        {
          method: "PUT",
        }
      );

      if (response.ok) {
        const updatedData = await response.json();
        setStats((prevStats) => ({
          ...prevStats,
          num_likes: updatedData?.num_likes ?? stats.num_likes,
        }));
      } else {
        console.error("Failed to update like");
      }
    } catch (error) {
      console.error("Error toggling like:", error);
    }
  };

  const fetchStats = async () => {
    try {
      const response = await fetch(
        `https://api.be-native.life/api/blog/${documentId}/stats`
      );
      const data = await response.json();
      setStats((prevStats) => ({
        ...prevStats,
        num_likes: data?.num_likes ?? 0,
        num_views: data?.num_views ?? 0,
        num_comments: data?.num_comments ?? 0,
      }));
    } catch (error) {
      console.error("Error fetching stats:", error);
    }
  };

  const incrementViewCount = async () => {
    if (!localStorage.getItem(`viewed_${documentId}`)) {
      try {
        await fetch(
          `https://api.be-native.life/api/blog/views?blog_id=${documentId}`,
          {
            method: "POST",
          }
        );

        localStorage.setItem(`viewed_${documentId}`, "true");
      } catch (error) {
        console.error("Error incrementing view count:", error);
      }
    }
  };

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        setLoading(true);
        const fetchedArticle = await fetchApi({
          endpoint: "articles",
          wrappedByKey: "data",
          wrappedByList: true,
          query: {
            "populate[0]": "Cover",
            "pagination[pageSize]": "1",
            "sort[0]": "published_date:desc",
            "filters[documentId][$eqi]": documentId,
            locale: language === "ja" ? "ja-JP" : "en",
          },
        });
        setArticle(fetchedArticle[0]);
        fetchStats();
        incrementViewCount();
      } catch (error) {
        console.error("Error fetching articles:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchArticles();
  }, [documentId, language]);

  const hashtags = article?.hashtags_comma_seperated
    ? article?.hashtags_comma_seperated
        ?.split(",")
        .map((tag) => `#${tag.trim()?.replace("#", "")}`)
    : [];

  return loading ? (
    <div className="min-h-[100svh] max-w-[450px] w-full flex flex-col items-center justify-center gap-2">
      <p className="text-[24px] font-bold text-neutral-800">
        <RenderTranslatedText i8nKey={"loading_text"} />
      </p>
    </div>
  ) : (
    <>
      <div className="w-full p-5 h-[60px] max-w-[450px] mx-auto flex items-center bg-white">
        <div onClick={() => navigate(-1)} className="flex-shrink-0">
          <img className="w-[24px]" src={backButton} />
        </div>
        <p className="font-semibold text-lg capitalize text-center flex-grow mr-8">
          {article ? article.Category : "Loading..."}
        </p>
      </div>

      <div className="p-5 max-w-[450px] mx-auto otaPageBg min-h-[80vh]">
        <div>
          <div key={article.id}>
            <img
              className="w-full h-40 rounded-xl"
              src={article.Cover.url}
              alt={article.id}
            />
            <p className="font-semibold text-lg mt-2">{article.Title}</p>
            <p className="font-semibold my-2">{hashtags.join(" ")}</p>

            <div className="flex space-x-2 my-3">
              <div className="flex flex-row items-center gap-1 border-2 p-1.5 rounded-2xl bg-white">
                <img src={eyes} className="w-[18px] object-contain" />
                <p className="text-[12px] text-[#424242]">{stats.num_views}</p>
              </div>
              <div
                onClick={toggleLike}
                className="flex flex-row items-center gap-1 border-2 p-1.5 rounded-2xl bg-white"
              >
                <img
                  src={article.liked ? heartFilled : heartOutline}
                  className="w-[18px] object-contain"
                />
                <p className="text-[12px] text-[#424242]">{stats.num_likes}</p>
              </div>
              {/* <div className="flex flex-row items-center gap-1 border-2 p-1.5 rounded-2xl bg-white">
                <img src={commentIcon} className="w-[18px] object-contain" />
                <p className="text-[12px] text-[#424242]">
                  {stats.num_comments}
                </p>
              </div> */}
            </div>

            <Markdown remarkPlugins={[remarkGfm]} className={"article-content"}>
              {article.content}
            </Markdown>
          </div>
          <div className="my-4">
            <ShareSection
              shareLink={window.location.href}
              customShareTitle={"Check out this article at"}
              cardTitle={t(
                "home_category_blog_detail_page_share_section_title"
              )}
            />
          </div>
          {/* <div className="my-4">
            <CommentSection />
          </div> */}
        </div>
      </div>
    </>
  );
};

export default HomeCategoryArticle;
