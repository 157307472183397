import React, { useEffect, useState } from "react";
import RenderTranslatedText from "../../components/RenderTranslatedText";
import { backButton } from "../../assets/icons";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { otaQuizBackendBaseUrl } from "../../utils/auth";

export const singleCommunityNavConfig = [
  {
    path: "/community/:communityId", // Dynamic communityId
    name: "top_nav_community",
  },
  {
    path: "/:communityId/quiz", // Dynamic communityId
    name: "top_nav_participated_quiz",
  },
];

function CommunityQuiz() {
  const navigate = useNavigate();
  const { communityId } = useParams(); // Extracting communityId from useParams
  const [isActive, setIsActive] = useState("top_nav_participated_quiz");
  const [quizData, setQuizData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const {
    i18n: { language },
  } = useTranslation();

  const handleNavClick = (item) => {
    const updatedPath = item.path.replace(":communityId", communityId); 
    navigate(updatedPath);
    setIsActive(item.name);
  };

  const handleQuizNavigation = async (quizId) => {
    const userId = localStorage.getItem("USER_ID");
    try {
      const response = await axios.get(
        `${otaQuizBackendBaseUrl.url}/${quizId}/${userId}/response-check`
      );

      if (response.data.exists) {
        navigate(`/quiz/${quizId}/response`);
      } else {
        navigate(`/quiz/${quizId}`);
      }
    } catch (error) {
      console.error("Error checking response:", error);
    }
  };

  useEffect(() => {
// Fetch quizzes by communityId
const fetchQuizzes = async () => {
  try {
    const response = await axios.get(
      `${otaQuizBackendBaseUrl.url}/community/${communityId}?lang=${language}`
    );
    setQuizData(response.data);
    setLoading(false);
  } catch (err) {
    console.error("Error fetching quizzes:", err);
    setError(err.response?.data?.message || "Failed to fetch quizzes.");
    setLoading(false);
  }
};

    fetchQuizzes();
  }, [communityId,language]);

  return (
    <div>
      {/* Header */}
      <div className="p-5 w-full h-[60px] gap-2 bg-white max-w-[450px] mx-auto flex items-center border-b-2 border-gray-300">
        <div>
          <img
            className="w-[24px]"
            onClick={() => navigate(-1)}
            src={backButton}
            alt="Back"
          />
        </div>
        <p className="font-semibold text-[17px] text-[#414853]">
          <RenderTranslatedText i8nKey={"top_nav_participated_quiz"} />
        </p>
      </div>

      {/* Navigation */}
      <div className="flex items-center justify-between border-b-2">
        {singleCommunityNavConfig.map((item) => (
          <p
            key={item.path}
            onClick={() => handleNavClick(item)}
            className={`cursor-pointer w-full text-center py-2 ${
              isActive === item.name
                ? "gradient-text font-bold border-b-4 border-gradient"
                : "text-[#667085]"
            }`}
          >
            <RenderTranslatedText i8nKey={item.name} />
          </p>
        ))}
      </div>

      {/* Quiz Section */}
      <div className="otaPageBg max-w-[450px] min-h-screen mx-auto">
        <div className="p-5 max-w-[450px] mx-auto">
          {loading ? (
            <p className="h-[600px] flex text-center justify-center items-center font-semibold text-lg">
              Loading quizzes...
            </p>
          ) : error ? (
            <p className="h-[200px] flex text-center justify-center items-center font-semibold text-lg">
              {error}
            </p>
          ) : (
            <div className="grid grid-cols-2 gap-4 w-full">
              {quizData.map((quiz) => (
                <div
                  key={quiz.quizId}
                  onClick={() => handleQuizNavigation(quiz.quizId)}
                  className="relative w-full h-[250px] rounded-lg overflow-hidden shadow-lg"
                >
                  <img
                    src={quiz.coverImg}
                    alt={quiz.quizId}
                    className="w-full h-full object-cover"
                  />
                  <div className="absolute bottom-0 left-0 right-0 h-[200px] bg-gradient-to-t from-black to-transparent"></div>
                  <p className="absolute bottom-2 left-2 text-white text-sm">
                    {quiz.titleEn || quiz.titleJa}
                  </p>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default CommunityQuiz;
