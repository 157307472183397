import { t } from "i18next";
import { demonSlayer, naruto, onePiece } from "../../assets/anime";

export const animeShowsConfig = [
  {
    cover: onePiece,
    titleKey: "community_one_piece",
    key: "one_piece",
  },
  {
    cover: demonSlayer,
    titleKey: "community_demon_slayer",
    key: "demon_slayer",
  },
  {
    cover: naruto,
    titleKey: "community_demon_naruto",
    key: "naruto",
  },
];

export const communityNavConfig = [
  {
    path: "/characters",
    name: "top_nav_fav",
  },
  {
    path: "/community",
    name: "top_nav_community",
  },
  {
    path: "/quiz",
    name: "top_nav_quiz",
  },
  // {
  //   path: "/physiological_test",
  //   name: "top_nav_test",
  // },
];
