import React from "react";
import { useNavigate } from "react-router-dom";

const RankCard = ({ data, goldenCrown, silverCrown, bronzeCrown }) => {
  const navigate=useNavigate();
  
  return (
    <div  className="divide-y">
      {data.map((rank, index) => (
        <div
        onClick={()=>navigate(`/user-profile/${rank.userId}`)}
          key={index}
          className="grid grid-cols-3 items-center justify-center mx-4 py-2 hover:bg-gray-100"
        >
          {/* Rank and Badge */}
          <div className="flex items-center ml-4">
            {/* Crown and Rank */}
            <div className="flex flex-col items-center ">
              {rank.rank === 1 && (
                <>
                  <img src={goldenCrown} alt="first" className="w-6 h-6 mb-1" />
                  <span
                    className="text-sm font-bold"
                    style={{ color: "#FCC500" }}
                  >
                    1st
                  </span>
                </>
              )}
              {rank.rank === 2 && (
                <>
                  <img
                    src={silverCrown}
                    alt="second"
                    className="w-6 h-6 mb-1"
                  />
                  <span
                    className="text-sm font-bold"
                    style={{ color: "#ADADAD" }}
                  >
                    2nd
                  </span>
                </>
              )}
              {rank.rank === 3 && (
                <>
                  <img src={bronzeCrown} alt="third" className="w-6 h-6 mb-1" />
                  <span
                    className="text-sm font-bold"
                    style={{ color: "#DF8A4C" }}
                  >
                    3rd
                  </span>
                </>
              )}
              {rank.rank > 3 && (
                <span
                  className="text-sm font-bold"
                  style={{ color: "#5297FF" }}
                >
                  {rank.rank}th
                </span>
              )}
            </div>
</div>
            {/* Character Image and Details */}
            <div className="flex items-center justify-start -ml-8">
            <img src={`https://api.be-native.life/api/friends/charimg/${rank.userType||rank.nativeType}`} alt={rank.name} className="w-12 h-12 " />
            <div className="ml-2 max-w-28">
              <h3 className="font-bold text-gray-800">{rank.name}</h3>
              <p className="text-xs  text-[#BF89D8] font-semibold">
                {rank.description}
              </p>
            </div></div>
          

          {/* Points and Native Type */}
          <div className="flex flex-col items-end mr-4">
            <span className="text-lg font-bold text-gray-600">
              {rank.pts}pts
            </span>
            <span style={{color:rank.color}} className=" text-lg font-bold">
              {rank.nativeType||rank.userType}
            </span>
          </div>
          {/* <img className="-mr-5" src={arrowNext} alt="arrow" /> */}
        </div>
      ))}
    </div>
  );
};

export default RankCard;
