import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import RenderTranslatedText from "../../components/RenderTranslatedText";
import { getFileUrl } from "../../components/ImageUpload/constants";
import {
  backButton,
  heartOutline,
  commentIcon,
  heartFilled,
} from "../../assets/icons";
import { noChar, noThreads } from "../../assets";
import OtaGradientOutlineButton from "../../components/OtaGradientOutlineButton";
import OtaGradientButton from "../../components/OtaGradientButton";
import { useTranslation } from "react-i18next";
import useResetScroll from "../../utils/useResetScroll";

function ThreadSingle() {
  useResetScroll();
  const { key, id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();
  const [communityData, setCommunityData] = useState(null);
  const [error, setError] = useState(null);
  const [currentThread, setCurrentThread] = useState([]);
  const [isLiking, setIsLiking] = useState(false);
  const [currentThreadMessages, setCurrentThreadMessages] = useState([]);
  const [postMessage, setPostMessage] = useState("");
  const [triggerAPI, setTriggerAPI] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isPosting, setIsPosting] = useState(false);

  function formatDate(dateString, t) {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();

    return `${t("posted_on_text")} ${day}/${month}/${year}`;
  }

  useEffect(() => {
    (async () => {
      if (!id) return;
      const currentThreadApiRes = await fetch(
        `https://api.be-native.life/api/travel/favthread/${id}`
      );
      const currentThreadData = await currentThreadApiRes.json();
      setCurrentThread(currentThreadData);
      const apiRes = await fetch(
        `https://api.be-native.life/api/travel/favthread/${id}/messages?user_id=${userId}`
      );
      const data = await apiRes.json();
      setCurrentThreadMessages(
        data?.sort(
          (a, b) =>
            new Date(a?.created_at).getTime() -
            new Date(b?.created_at).getTime()
        )
      );
    })();
  }, [id, location, triggerAPI]);

  const userId = localStorage.getItem("USER_ID");

  const handlePostMessage = async () => {
    if (isPosting) return;
    if (postMessage?.length === 0) return;
    try {
      setIsPosting(true);
      const res = await fetch(
        "https://api.be-native.life/api/travel/favthread/messages",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            thread_id: id,
            creator_user_id: localStorage.getItem("USER_ID"),
            message: postMessage,
            param1: "",
            param2: localStorage.getItem("USER_NAME"),
            param3: localStorage.getItem("USER_QR_COLOR"),
            param4: localStorage.getItem("USER_NATIVE_TYPE"),
            param5: "string",
            param6: "string",
            param7: "string",
          }),
        }
      );
      setPostMessage("");
      setTriggerAPI((p) => !p);
    } catch (_) {
    } finally {
      setIsPosting(false);
    }
  };

  useEffect(() => {
    if (key) {
      fetchCommunityData();
    }
    window.scrollTo(0, 0);
    setCurrentThread(null);
  }, [key]);

  const handleLikeMessage = async (messageId) => {
    if (isLiking) return;
    setIsLiking(true);
    try {
      await fetch(
        `https://api.be-native.life/api/travel/favthread/messages/${messageId}/likes?user_id=${localStorage.getItem(
          "USER_ID"
        )}`,
        {
          method: "POST",
        }
      );
      setTriggerAPI((p) => !p);
    } catch (_) {
    } finally {
      setIsLiking(false);
    }
  };

  const fetchCommunityData = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch(
        `https://api.be-native.life/api/travel/favcard/${key}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (!response.ok) {
        throw new Error("Failed to fetch community data");
      }

      const data = await response.json();
      setCommunityData(data);
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  return isLoading ? (
    <div className="min-h-[100svh] w-full flex flex-col items-center justify-center gap-2">
      <p className="text-[24px] font-bold text-neutral-800">
        <RenderTranslatedText i8nKey={"loading_text"} />
      </p>
    </div>
  ) : (
    <div>
      <div className="p-5 w-full h-[60px] bg-white max-w-[450px] gap-2 mx-auto flex items-center border-b-2 border-gray-300">
        <div onClick={() => navigate(-1)}>
          <img className="w-[24px]" src={backButton} alt="Back" />
        </div>
        <p className="font-semibold text-[17px] text-[#414853] ">
          {communityData ? (
            <RenderTranslatedText i8nKey={communityData.title} />
          ) : (
            <RenderTranslatedText i8nKey={"loading_text"} />
          )}
        </p>
      </div>
      <div className=" otaPageBg max-w-[450px] min-h-[80svh] mx-auto">
        {currentThread && currentThread.length > 0 && (
          <h1 className="font-semibold text-lg px-5">
            <RenderTranslatedText
              i8nKey={"community_single_page_thread_title"}
            />
          </h1>
        )}
        {currentThread && (
          <>
            <div className="mb-2 p-5">
              <div
                className="w-full grid rounded-lg gap-3 rounded-bl-none"
                style={{ gridTemplateColumns: "1fr 7fr" }}
              >
                <div>
                  <img
                    className="aspect-square object-contain"
                    src={`https://api.be-native.life/api/friends/charimg/${currentThread.param4}`}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      navigate(
                        `/user-profile/${currentThread?.creator_user_id}`
                      );
                    }}
                  />
                </div>
                <div>
                  <p className="font-semibold text-[14px]">
                    {currentThread.param2}
                  </p>
                  <p className="text-[10px] text-[#808691] my-1">
                    {formatDate(currentThread.created_at, t)}
                  </p>
                  <p className="font-medium text-[12px]">
                    {currentThread.title}
                  </p>
                  <p className="text-[#808691] text-[12px]">
                    {currentThread.param1}
                  </p>
                  <div className=" w-min py-1.5 rounded-b-lg flex items-center gap-2">
                    <div className="flex flex-row items-center gap-1 border-2 p-1.5 rounded-xl bg-white">
                      <img
                        onClick={() => handleLikeMessage(id)}
                        src={heartOutline}
                        className="min-w-[18px] object-contain"
                      />
                      <p className="text-[12px] text-[#424242]">
                        {currentThread.num_likes}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        {currentThreadMessages?.map(
          ({
            param4,
            message,
            param2,
            num_liked,
            message_id,
            created_at,
            liked = false,
            creator_user_id,
          }) => {
            return (
              <div className="w-full flex flex-col items-end">
                <div className=" w-[85%] flex flex-col items-end">
                  <div
                    className="w-full grid px-5 rounded-lg gap-3 rounded-bl-none"
                    style={{ gridTemplateColumns: "1fr 7fr" }}
                  >
                    <div>
                      <img
                        className="aspect-square object-contain"
                        src={`https://api.be-native.life/api/friends/charimg/${param4}`}
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          navigate(`/user-profile/${creator_user_id}`);
                        }}
                      />
                    </div>
                    <div className="py-2">
                      <p className="font-semibold text-[14px]">{param2}</p>
                      <p className="text-[10px] text-[#808691] my-1">
                        {formatDate(created_at, t)}
                      </p>
                      <p className="text-[#000] text-[12px]">{message}</p>
                      <div className=" w-min py-1.5  rounded-b-lg flex items-center gap-2">
                        {liked ? (
                          <div className="flex flex-row items-center gap-1 border-2 p-1.5 rounded-xl ">
                            <img
                              src={heartFilled}
                              className="min-w-[18px] object-contain"
                            />
                            <p className="text-[12px] text-[#424242]">
                              {num_liked}
                            </p>
                          </div>
                        ) : (
                          <div
                            className="flex flex-row items-center gap-1 border-2 p-1.5 rounded-xl bg-white"
                            onClick={() => handleLikeMessage(message_id)}
                          >
                            <img
                              src={heartOutline}
                              className="min-w-[18px] object-contain"
                            />
                            <p className="text-[12px] text-[#424242]">
                              {num_liked}
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          }
        )}
      </div>
      <div className="fixed bottom-0 w-full bg-white p-4 border-t-2 border-gray-300">
        <div className="flex items-center justify-between">
          <input
            type="text"
            value={postMessage}
            onChange={(e) => setPostMessage(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded-lg"
            placeholder={t("thread_single_page_comment_input")}
          />

          <OtaGradientButton
            onClick={() => handlePostMessage()}
            className="rounded-[10px] bg-[#333] w-[30%] h-[40px]  text-white ml-2"
          >
            {t("thread_single_page_send_cta")}
          </OtaGradientButton>
        </div>
      </div>
    </div>
  );
}

export default ThreadSingle;
