import React, { useEffect, useState } from "react";
import { backButton } from "../../assets/icons";
import { useNavigate, useParams } from "react-router-dom";
import ShareSection from "../../components/ShareSection";
import { useTranslation } from "react-i18next";
import OtaGradientButton from "../../components/OtaGradientButton";
import { balloon, balloons, congratulations, crown } from "../../assets";
import RenderTranslatedText from "../../components/RenderTranslatedText";
import axios from "axios";
import { otaQuizBackendBaseUrl } from "../../utils/auth";

const QuizScore = () => {
  const navigate = useNavigate();
  const {
    i18n: { language },
    t,
  } = useTranslation();
  const today = new Date();
  const isMonday = today.getDay() === 1;

  const { quizId } = useParams();
  const userId = localStorage.getItem("USER_ID");
  const [quiz, setQuiz] = useState();
  const [score, setScore] = useState();
  const [loading, setLoading] = useState(true);
  const [topUsers, setTopUsers] = useState([]);

  useEffect(() => {
    const fetchQuiz = async () => {
      try {
        const response = await axios.get(
          `${otaQuizBackendBaseUrl.url}/${quizId}?lang=${language}`
        );
        setQuiz(response.data);
      } catch (error) {
        console.error("Error fetching quiz data:", error);
      } finally {
        setLoading(false);
      }
    };

    const fetchScore = async () => {
      try {
        const response = await axios.get(
          `${otaQuizBackendBaseUrl.url}/${quizId}/${userId}/scorefetch`
        );
        let marks = (response.data.tempScore >= 0) 
            ? response.data.tempScore 
            : response.data.score;
        const percentage = (marks / 10) * 100;
        setScore(percentage);
      } catch (error) {
        console.error("Error fetching quiz data:", error);
      }
    };

    const getTop3UsersForQuiz = async () => {
      try {
        const response = await axios.get(
          `${otaQuizBackendBaseUrl.url}/${quizId}/topThree`
        );

        // Assuming the response contains the 'topUsers' array
        const { topUsers } = response.data;

        // Set the top 3 users to your state or handle as needed
        setTopUsers(topUsers);
      } catch (error) {
        console.error("Error fetching top 3 users:", error);
      }
    };

    getTop3UsersForQuiz();
    fetchScore();
    fetchQuiz();
  }, [language, quizId, userId]);

  const handlePlayAgain =  () => {
    
        navigate(`/quiz/${quizId}/play`);
     
  };

  if (loading) {
    return <div className="otaPageBg h-screen flex justify-center items-center font-semibold">            
      <RenderTranslatedText i8nKey={"loading_text"} />
        </div>
  }

  return (
    <div className="otaPageBg min-h-screen w-full mx-auto overflow-hidden relative">
      {/* Header */}
      <div className="flex items-center p-4 bg-white shadow-md">
        <button onClick={() => navigate(-1)}>
          <img src={backButton} alt="Back" className="w-6" />
        </button>
        <h1 className="text-lg font-semibold text-gray-800 ml-4">
          {quiz.titleEn || quiz.titleJa}
        </h1>
      </div>

      {/* Score Section */}
      <div className="relative otaGradientButton p-4 py-10 flex flex-col items-center">
        <div className="absolute top-[-40px] right-[-20px] w-[100px] h-[100px] bg-white rounded-full opacity-10 z-0"></div>
        <div className="absolute top-[-20px] right-[-30px] w-[100px] h-[100px] bg-white rounded-full opacity-10 z-0"></div>
        <div className="absolute bottom-[-5px] left-[-40px] w-[100px] h-[100px] bg-white rounded-full opacity-10 z-0"></div>
        <div className="absolute bottom-[-20px] left-[-20px] w-[100px] h-[100px] bg-white rounded-full opacity-10 z-0"></div>

        <div className="relative flex justify-center items-center h-40 w-40">
          {/* Outer Circles */}
          <div className="absolute h-[160px] w-[160px] bg-white rounded-full opacity-30"></div>
          <div className="absolute h-[140px] w-[140px] bg-white rounded-full opacity-30"></div>

          {/* Main Circle */}
          <div className="relative bg-white rounded-full h-[120px] w-[120px] flex flex-col items-center justify-center z-10">
            <p className="font-semibold text-gray-800">Your Score</p>
            <p className="gradient-text font-bold text-3xl my-2">{score}%</p>
          </div>

          {/* Balloons and Congratulations */}
          {(score === 100 || (isMonday && score === 200)) && (
            <>
              <img
                className="absolute top-[-20px] right-[120px] z-10"
                src={balloon}
                alt="Balloons"
              />
              <img
                className="absolute top-[120px] z-20"
                src={congratulations}
                alt="Congratulations"
              />
              <img
                className="absolute top-[-20px] left-[120px] z-10"
                src={balloons}
                alt="Balloons Rotated"
              />
            </>
          )}
        </div>

        {((score !== 100 && !isMonday) || (isMonday && score !== 200)) && (
          <p className="text-white text-xs mt-4 px-4 text-center">
            <RenderTranslatedText i8nKey={"quiz_expression"} />
          </p>
        )}
      </div>

       {isMonday && (<div className="flex  m-5 rounded-lg px-4 py-2 items-center justify-between border-2 border-[#FC8600] bg-[#F5E9DE]">
        <div className="flex space-x-2 items-center">
          <p className="text-[16px] font-semibold text-[#344054]">
           
              <p>It's Monday! Your final score will be doubled! 🎉 </p>
           
          </p>
        </div>
        <div
          onClick={() => navigate(`/quiz/${quiz.quizId}/ranking`)}
          className="cursor-pointer text-center hover:underline text-[14px] otaFont font-semibold"
        >
          <RenderTranslatedText i8nKey={"quiz_page_view_ranking"} />
        </div>
      </div> )}

      <div className="my-4 px-2">
        <ShareSection
          shareLink={window.location.href}
          customShareTitle={"Check out this article at"}
          cardTitle={t("home_category_blog_detail_page_share_section_title")}
        />
      </div>

      <div className="flex items-center justify-between my-4 px-5">
        <p className="font-bold">Top 3 in this Quiz</p>
        <p
          onClick={() => navigate(`/quiz/${quizId}/ranking`)}
          className="otaFont underline font-semibold"
        >
          <RenderTranslatedText i8nKey={"quiz_page_view_ranking"} />
        </p>
      </div>

      <div className="grid grid-cols-3 gap-4 px-4 max-w-[450px] mb-14">
        {topUsers.map((user) => (
          <div
            key={user.rank}
            onClick={() => navigate(`/user-profile/${user.userId}`)}
            className="card p-4 rounded-lg shadow-lg"
            style={{
              backgroundColor: "white", // White background color
              borderTop: `8px solid ${user.color}`, // Normal opacity for top border
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {" "}
            <p
              className="font-bold "
              style={{ color: `${user.color}99` }} // 30% opacity for text
            >
              {user.userType}
            </p>
            <img
              src={user.characterImg}
              alt={user.name}
              className="w-24 h-24 object-cover rounded-full mb-4"
            />
            <p
              className="text-xl font-semibold"
              style={{ color: `${user.color}99` }} // 30% opacity for text
            >
              {user.name}
            </p>
            <p
              style={{ color: `${user.color}99` }} // 30% opacity for text
              className="text-lg font-semibold"
            >
              Rank: {user.rank}
            </p>
          </div>
        ))}
      </div>

      <div className="p-2 px-5 bg-white fixed bottom-0 py-4 border-t-2 w-full">
        <OtaGradientButton
          onClick={() => handlePlayAgain()}
          className="rounded-xl py-3"
        >
          <RenderTranslatedText i8nKey={"quiz_page_play_again_cta"} />
        </OtaGradientButton>
      </div>
    </div>
  );
};

export default QuizScore;
