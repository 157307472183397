import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  backButton,
  bronzeCrown,
  goldenCrown,
  silverCrown,
} from "../../assets/icons";
import ShareSection from "../../components/ShareSection";
import RankCard from "./component/RankCard";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { otaQuizBackendBaseUrl } from "../../utils/auth";
import RenderTranslatedText from "../../components/RenderTranslatedText";

const QuizRanking = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const {
    i18n: { language },
  } = useTranslation();

  // States
  const [quizRanking, setQuizRanking] = useState([]);
  const [quizData, setQuizData] = useState(null);
  const [loadingRanking, setLoadingRanking] = useState(true); // Loading state for rankings
  const [error, setError] = useState(null); // Error handling state
  const { quizId } = useParams();

  const handleQuizNavigation = async (quizId) => {
    const userId = localStorage.getItem("USER_ID");
    try {
      const response = await axios.get(
        `${otaQuizBackendBaseUrl.url}/${quizId}/${userId}/response-check`
      );

      if (response.data.exists) {
        navigate(`/quiz/${quizId}/response`);
      } else {
        navigate(`/quiz/${quizId}`);
      }
    } catch (error) {
      console.error("Error checking response:", error);
    }
  };

  useEffect(() => {
    const fetchQuizData = async () => {
      try {
        const lang = language; // Get current language (either 'en' or 'ja')

        // Fetch quiz data and rankings in parallel
        const [quizResponse, rankingResponse] = await Promise.all([
          axios.get(`${otaQuizBackendBaseUrl.url}/${quizId}?lang=${lang}`),
          axios.get(`${otaQuizBackendBaseUrl.url}/${quizId}/rankings`),
        ]);

        setQuizData(quizResponse.data);
        setQuizRanking(rankingResponse.data.rankings || []);
      } catch (error) {
        console.error("Error fetching quiz data or rankings:", error);
        setError("Failed to load quiz data. Please try again later.");
      } finally {
        setLoadingRanking(false); // End loading state for rankings
      }
    };

    fetchQuizData();
  }, [quizId, language]);

  // Error state UI
  if (error) {
    return (
      <div className="quiz-page otaPageBg min-h-screen max-w-[450px] mx-auto relative">
        <div className="flex justify-center items-center h-full">
          <p className="text-red-500 font-semibold">{error}</p>
        </div>
      </div>
    );
  }

  return (
    <div className="quiz-page otaPageBg min-h-screen max-w-[450px] mx-auto relative">
      {/* Header */}
      <div className="flex items-center p-4 bg-white shadow-md">
        <button onClick={() => handleQuizNavigation(quizData.quizId)}>
          <img src={backButton} alt="Back" className="w-6" />
        </button>
        <h1 className="text-lg font-semibold text-gray-800 ml-4">
          {t("quiz_ranking")}
        </h1>
      </div>

      <div className="mx-5">
        <p className="my-4 text-gray-500 font-semibold text-sm">
          Here's the ranking for{" "}
          <span>{quizData?.titleEn || quizData?.titleJa || "this"}</span> quiz.
        </p>

        {/* Ranking Section */}
        <div
          className="bg-white rounded-xl border-2 " 
        >
          <div className="otaGradientButton text-white font-semibold py-2 rounded-t-xl text-center">
            {t("top_10_rankings")}
          </div>
          {loadingRanking ? (
            <div className="p-4 text-center">
              <RenderTranslatedText i8nKey={"loading_text"} />
            </div>
          ) : quizRanking.length > 0 ? (
            <RankCard
              data={quizRanking}
              goldenCrown={goldenCrown}
              silverCrown={silverCrown}
              bronzeCrown={bronzeCrown}
            />
          ) : (
            <p className="p-4 text-center text-gray-500">
              {t("no_rankings_available")}
            </p>
          )}
        </div>
      </div>

      {/* Share Section */}
      <div className="my-4 px-2">
        <ShareSection
          shareLink={window.location.href}
          customShareTitle={"Check out this article at"}
          cardTitle={t("home_category_blog_detail_page_share_section_title")}
        />
      </div>
    </div>
  );
};

export default QuizRanking;
