import React from "react";

export default function OtaGradientButton({
  children,
  onClick = () => {},
  className,
}) {
  return (
    <button
      className={`otaGradientButton w-full text-white font-semibold ${className}`}
      onClick={onClick}
    >
      {children}
    </button>
  );
}
