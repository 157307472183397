import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import OtaGradientOutlineButton from "../../components/OtaGradientOutlineButton";
import RenderTranslatedText from "../../components/RenderTranslatedText";
import { backButton } from "../../assets/icons";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { otaQuizBackendBaseUrl } from "../../utils/auth";

export default function QuizSingle() {
  const navigate = useNavigate();
  const [quizData, setQuizData] = useState(null); 
  const{
    i18n: { language },
  } = useTranslation();
  const { quizId } = useParams();

  useEffect(() => {
    const fetchQuiz = async () => {
      try {
        const lang = language; // Get current language (either 'en' or 'ja')
        const response = await axios.get(
          `${otaQuizBackendBaseUrl.url}/${quizId}?lang=${lang}`
        );
        setQuizData(response.data);
      } catch (error) {
        console.error("Error fetching quizzes:", error);
      }
    };

    fetchQuiz();
  }, [language, quizId]);

  if (!quizData) {
    return (
      <div className="otaPageBg h-screen flex justify-center items-center font-semibold">            
            <RenderTranslatedText i8nKey={"loading_text"} />
              </div>
    );
  }

  return (
    <div className="relative w-full h-[88vh] pb-20 bg-black flex flex-col">
      {/* Background Image */}
      <div className="absolute w-full h-full">
        <img
          src={quizData.coverImg}
          alt="Anime Background"
          className="w-full h-full object-cover"
        />
        {/* Gradient Overlay */}
        <div className="absolute inset-0 bg-gradient-to-t from-black to-transparent"></div>
      </div>

      {/* Content */}
      <div className="relative flex flex-col justify-end items-center h-full px-5 text-center">
        {/* Back Button */}
        <div
          className="absolute top-5 left-5 bg-white bg-opacity-80 p-2 rounded-full shadow-md"
          onClick={() => navigate(-1)}
        >
          <img className="w-[24px]" src={backButton} alt="Back" />
        </div>
        <h1 className="text-white text-3xl font-bold">
          {quizData.titleEn || quizData.titleJa}
        </h1>{" "}
        <p className="text-white text-sm my-8">
          {quizData.descriptionEn || quizData.descriptionJa}
        </p>{" "}
        <OtaGradientOutlineButton
          onClick={() => navigate(`/quiz/${quizId}/play`)}
          className="relative z-10"
        >
          <p className="font-semibold px-10">
            <RenderTranslatedText i8nKey={"quiz_page_play_now_cta"} />
          </p>
        </OtaGradientOutlineButton>
      </div>
    </div>
  );
}
