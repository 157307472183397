import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { backButton } from "../../assets/icons";
import {
  crossIcon,
  greenTickIcon,
  redCrossIcon,
  TestQuizCategoryImg3,
  tickIcon,
} from "../../assets";
import OtaGradientButton from "../../components/OtaGradientButton";
import OtaGradientOutlineButton from "../../components/OtaGradientOutlineButton";
import axios from "axios";
import { useTranslation } from "react-i18next";
import RenderTranslatedText from "../../components/RenderTranslatedText";
import { otaQuizBackendBaseUrl } from "../../utils/auth";

export default function QuizPage() {
  const navigate = useNavigate();
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedOptions, setSelectedOptions] = useState({});
  const [feedback, setFeedback] = useState({});
  const [answeredQuestions, setAnsweredQuestions] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [quizQuestions, setQuizQuestions] = useState([]);
  const [quiz, setQuiz] = useState();
  const [loading, setLoading] = useState(true);
  const { quizId } = useParams();
  const [quizResponses, setQuizResponses] = useState([]);
  const quizResponsesRef = useRef([]);
  const [score, setScore] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [trackerFeedback, setTrackerFeedback] = useState({});

  const userId = localStorage.getItem("USER_ID");
  const {
    t,
    i18n: { language },
  } = useTranslation();

  useEffect(() => {
    const fetchQuizData = async () => {
      try {
        const response = await axios.get(
          `${otaQuizBackendBaseUrl.url}/${quizId}/questions?lang=${language}`
        );
        setQuizQuestions(response.data);
      } catch (error) {
        console.error("Error fetching quiz questions:", error);
      }
    };

    const fetchQuiz = async () => {
      try {
        const response = await axios.get(
          `${otaQuizBackendBaseUrl.url}/${quizId}?lang=${language}`
        );
        setQuiz(response.data);
      } catch (error) {
        console.error("Error fetching quiz:", error);
      }
    };

    // Execute both API calls and set loading to false only after both complete
    const fetchData = async () => {
      await Promise.all([fetchQuiz(), fetchQuizData()]);
      setLoading(false);
    };

    fetchData();
  }, [language, quizId]);

  const currentQuestion = quizQuestions[currentQuestionIndex];

  const handleCloseModal = () => {
    navigate(`/quiz/${quizId}/ota-score`);
  };

  const handleOptionClick = async (option) => {
    try {
      const response = await axios.post(
        `${otaQuizBackendBaseUrl.url}/${quizId}/validate-answer`,
        {
          questionId: currentQuestion.questionId,
          optionId: option.optionId,
        }
      );
  
      const { isCorrect, answerId, selectedOptionId } = response.data;
  
      setSelectedOptions((prev) => ({
        ...prev,
        [currentQuestionIndex]: option.optionId,
      }));
  
      setFeedback((prev) => ({
        ...prev,
        [currentQuestionIndex]: { isCorrect, answerId, selectedOptionId },
      }));
  
      if (!answeredQuestions.includes(currentQuestionIndex)) {
        setAnsweredQuestions((prev) => [...prev, currentQuestionIndex]);
      }
    } catch (error) {
      console.error("Error validating answer:", error);
    }
  };
 
  const handleNext = async () => {
    const selectedOptionId = selectedOptions[currentQuestionIndex];
    let updatedScore = score;
    if (selectedOptionId) {
      if (currentQuestion) {
        try {
          const response = await axios.post(
            `${otaQuizBackendBaseUrl.url}/${quizId}/validate-answer`,
            {
              questionId: currentQuestion.questionId,
              optionId: selectedOptionId,
            }
          );
  
          const { isCorrect, answerId } = response.data;
  
          setFeedback((prev) => ({
            ...prev,
            [currentQuestionIndex]: { isCorrect, answerId, selectedOptionId },
          }));
  
          setTrackerFeedback((prev) => ({
            ...prev,
            [currentQuestionIndex]: { isCorrect, selectedOptionId },
          }));
  
          if (!answeredQuestions.includes(currentQuestionIndex)) {
            setAnsweredQuestions((prev) => [...prev, currentQuestionIndex]);
          }

          if (isCorrect) {
            updatedScore += 2; 
          }
          setScore(updatedScore);
  
        const currentResponse = {
          questionId: currentQuestion.questionId,
          selectedOptionId: selectedOptionId,
        };
  
        const existingResponseIndex = quizResponsesRef.current.findIndex(
          (res) => res.questionId === currentResponse.questionId
        );
  
        if (existingResponseIndex === -1) {
          quizResponsesRef.current.push(currentResponse);
        } else {
          quizResponsesRef.current[existingResponseIndex] = currentResponse;
        }
  
        if (currentQuestionIndex === quizQuestions.length - 1) {
          await handleSubmit(updatedScore);
        } else {
          setCurrentQuestionIndex((prev) => prev + 1);
        }} catch (error) {
          console.error("Error validating answer:", error);
        }
      }
    } else {
      alert("Please select an option before moving to the next question.");
    }
  };
  

  const today = new Date();
  const isMonday = today.getDay() === 1;
  
  const handleSubmit = async (updatedScore) => {
    setIsSubmitting(true);

    const finalScore = isMonday ? updatedScore * 2 : updatedScore;
    const data = {
      userId,
      responses: quizResponsesRef.current,
      score: finalScore,
    };
  
    try {
      await axios.post(`${otaQuizBackendBaseUrl.url}/${quizId}/response`, data);
      await fetch(`${otaQuizBackendBaseUrl.url}/${quizId}/score`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ userId, score: finalScore }),
      });
  
      setIsModalOpen(true);
    } catch (error) {
      console.error("Error submitting quiz responses:", error);
    } finally {
      setIsSubmitting(false);
    }
  };
 
  const handleBack = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex((prev) => prev - 1);
    }
  };

  const optionLabels = ["A.", "B.", "C.", "D."];

  if (loading) {
    return (
      <div className="otaPageBg h-screen flex justify-center items-center font-semibold">
        <RenderTranslatedText i8nKey={"loading_text"} />
      </div>
    );
  }

  if (isSubmitting) {
    return (
      <div className="fixed inset-0 bg-gradient-to-br from-[#07b9ce] to-[#7d2ae7] opacity-30 flex justify-center items-center z-50">
        <h1 className="text-white text-xl font-bold">Submitting your quiz...</h1>
      </div>
    );
  }  

  return (
    <div className="quiz-page otaPageBg min-h-[190vw] max-w-[450px] mx-auto relative">
      <div className="flex items-center p-4 bg-white shadow-md">
        <button onClick={() => navigate(-1)}>
          <img src={backButton} alt="Back" className="w-6" />
        </button>
        <h1 className="text-lg font-semibold text-gray-800 ml-4">
          {quiz?.titleEn || quiz?.titleJa}
        </h1>
      </div>

      <div className="relative w-full h-52">
        <img
          src={quiz.coverImg}
          alt="Quiz Background"
          className="w-full h-full object-cover"
        />
        <div className="absolute inset-0 bg-black bg-opacity-50">
          <div className="h-28">
            <h1 className="text-white text-center my-4">Number of Questions</h1>
            <div className="flex items-center justify-center">
              <div className="flex gap-4">
              {quizQuestions.map((_, index) => {
  const isCurrent = index === currentQuestionIndex;
  const isCorrect = trackerFeedback[index]?.isCorrect;
  const isIncorrect =
    trackerFeedback[index]?.selectedOptionId && !trackerFeedback[index]?.isCorrect;

  return (
    <div
      key={index}
      className={`w-10 h-10 flex items-center justify-center rounded-full ${
        isCurrent
          ? "border-2 bg-white border-[#7d2ae7] rounded-full"
          : isCorrect
          ? "bg-green-500"
          : isIncorrect
          ? "bg-red-500"
          : "bg-white bg-opacity-40 text-white"
      }`}
    >
      {isCorrect ? (
        <img src={tickIcon} alt="Correct" className="w-4" />
      ) : isIncorrect ? (
        <img src={crossIcon} alt="Wrong" className="w-4" />
      ) : (
        <span
          className={`font-semibold text-xl ${
            isCurrent
              ? "bg-gradient-to-r from-[#07b9ce] via-[#3969e7] to-[#7d2ae7] bg-clip-text text-transparent"
              : ""
          }`}
        >
          {index + 1}
        </span>
      )}
    </div>
  );
})}

              </div>
            </div>
          </div>
          <h2 className="text-lg option-shadow bg-white border-2 m-4 p-4 rounded-2xl font-bold text-[#121212]">
            {currentQuestion?.questionEn || currentQuestion?.questionJa}
          </h2>

          <div className="px-4">
            <div className="space-y-4">
              {currentQuestion?.option_option_questionIdToquestion?.map(
                (option, index) => {
                  const isSelected =
                    selectedOptions[currentQuestionIndex] === option.optionId;
                  const isCorrect =
                    feedback[currentQuestionIndex]?.isCorrect &&
                    feedback[currentQuestionIndex]?.answerId === option.optionId;
                  const isIncorrect =
                    feedback[currentQuestionIndex]?.selectedOptionId ===
                      option.optionId && !feedback[currentQuestionIndex]?.isCorrect;

                  return (
                    <button
                      key={option.optionId}
                      className={`w-full flex justify-between items-center px-4 py-3 rounded-2xl border-2 option-shadow ${
                        isCorrect
                          ? "bg-[#E4F7EC] border-[#3ECF7B]"
                          : isIncorrect
                          ? "bg-[#FDEBEA] border-[#F95E59]"
                          : isSelected
                          ? "bg-white border-[#3969e7]"
                          : "bg-white"
                      }`}
                      onClick={() => handleOptionClick(option)}
                      disabled={answeredQuestions.includes(currentQuestionIndex)}
                    >
                      <p>
                        <span className="font-semibold">
                          {optionLabels[index]}{" "}
                        </span>
                        {option.optionEn || option.optionJa}
                      </p>
                      {isCorrect ? (
                        <img src={greenTickIcon} alt="Correct" className="w-5" />
                      ) : isIncorrect ? (
                        <img src={redCrossIcon} alt="Wrong" className="w-4" />
                      ) : null}
                    </button>
                  );
                }
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="fixed bottom-0 w-full bg-white p-3.5 border-t-2 border-gray-300">
        <div className="flex items-center justify-between">
          <OtaGradientOutlineButton onClick={handleBack} className="mr-1.5">
            <span className="font-semibold px-12  whitespace-nowrap">
              {t("quiz_page_back_cta")}
            </span>
          </OtaGradientOutlineButton>
          <OtaGradientButton
            onClick={handleNext}
            className="rounded-md w-full p-3 px-3.5 text-white ml-1.5"
            disabled={!selectedOptions[currentQuestionIndex]}
          >
            {currentQuestionIndex < quizQuestions.length - 1
              ? t("quiz_page_next_cta")
              : t("quiz_page_submit_cta")}
          </OtaGradientButton>
        </div>
      </div>

      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="relative bg-white mx-5 h-[450px] border-gradient border-4 py-6 px-4 rounded-lg text-center">
            {/* Dots at each corner */}
            <div className="absolute top-5 left-5 w-3 h-3 bg-[#3969e7] rounded-full" />
            <div className="absolute top-5 right-5 w-3 h-3 bg-[#3969e7] rounded-full" />
            <div className="absolute bottom-5 left-5 w-3 h-3 bg-[#3969e7] rounded-full" />
            <div className="absolute bottom-5 right-5 w-3 h-3 bg-[#3969e7] rounded-full" />

            <h2 className="text-2xl font-bold text-[#3969e7] mb-3">
              <RenderTranslatedText
                i8nKey={"compatibility_page_new_tag_modal_congratulations"}
              />
            </h2>
            <p className="text-[#292D32] mb-3 mx-10 text-lg font-semibold">
              <RenderTranslatedText i8nKey={"quiz_completed"} />
              <span className="text-sm">
                {isMonday
                  ? " It's Monday! Your efforts have earned you double points!"
                  : " Great job! Keep practicing to improve your score!"}
              </span>
            </p>

            {/* Display Score */}
            <h1 className="text-7xl font-bold gradient-text mb-6">
              {score} pts
            </h1>
            <p className="text-[#292D32] mb-4 mx-6 font-semibold">
              You got {Math.round((score / (isMonday ? 20 : 10)) * 100)}
              %. Check your ranking and challenge yourself to reach the top!
            </p>

            <div className="flex w-full justify-center items-center gap-4">
              <OtaGradientOutlineButton
                className="w-1/2"
                onClick={handleCloseModal}
              >
                <p className="font-semibold px-8">Close</p>
              </OtaGradientOutlineButton>
              <OtaGradientButton
                onClick={() => navigate(`/quiz/${quizId}/ranking`)}
                className="w-1/2 py-3 rounded-lg"
              >
                <RenderTranslatedText i8nKey={"quiz_page_check_ranking"} />
              </OtaGradientButton>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
