import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  backButton,
  bronzeCrown,
  goldenCrown,
  silverCrown,
} from "../../assets/icons";
import ShareSection from "../../components/ShareSection";
import RankCard from "../quizranking/component/RankCard";
import { useTranslation } from "react-i18next";
import axios from "axios";
import OtaGradientButton from "../../components/OtaGradientButton";
import { otaQuizBackendBaseUrl } from "../../utils/auth";

const QuizAllRanking = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const {
    i18n: { language },
  } = useTranslation();

  // State variables for rankings, pagination, and loading state
  const [rankings, setRankings] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);

  // Function to fetch rankings with pagination
  const fetchRankings = async (page) => {
    setLoading(true); // Set loading state to true
    try {
      const response = await axios.get(`${otaQuizBackendBaseUrl.url}/ranking/all`, {
        params: { page },
      });
      const { rankings, totalPages } = response.data; // Assuming the response contains `rankings` and `totalPages`
      setRankings(rankings);
      setTotalPages(totalPages);
    } catch (error) {
      console.error("Error fetching rankings:", error);
    } finally {
      setLoading(false); // Set loading state to false
    }
  };

  // Effect hook to fetch rankings when page changes
  useEffect(() => {
    fetchRankings(currentPage);
  }, [currentPage]);

  // Handle next page click
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  // Handle previous page click
  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <div className="quiz-page otaPageBg min-h-screen max-w-[450px] mx-auto relative">
      {/* Header */}
      <div className="flex items-center p-4 bg-white shadow-md">
        <button onClick={() => navigate(-1)}>
          <img src={backButton} alt="Back" className="w-6" />
        </button>
        <h1 className="text-lg font-semibold text-gray-800 ml-4">
          Entire Quiz Ranking
        </h1>
      </div>

      <div className="m-5">
        <div className="min-h-[350px]">
        {/* Ranking Section */}
        <div className="bg-white rounded-xl border-2  flex flex-col">
          <div className="otaGradientButton text-white font-semibold py-2 rounded-t-xl text-center">
            {t("top_rankings")}
          </div>
          {loading ? (
            <div className="flex justify-center items-center flex-1">
              <p className="text-gray-500">{t("loading")}</p>
            </div>
          ) : (
            <RankCard
              data={rankings}
              goldenCrown={goldenCrown}
              silverCrown={silverCrown}
              bronzeCrown={bronzeCrown}
            />
          )}
        </div></div>

        {/* Pagination Controls */}
        <div className="flex justify-between items-center mt-4">
          <OtaGradientButton
            onClick={handlePreviousPage}
            disabled={currentPage <= 1}
            className="rounded-lg py-4"
          >
            {t("previous")}
          </OtaGradientButton>
          <div className="text-center w-full font-semibold text-gray-700">
            {t("page_of", { currentPage, totalPages })}
          </div>
          <OtaGradientButton
            onClick={handleNextPage}
            disabled={currentPage >= totalPages}
            className="rounded-lg py-4"
          >
            {t("next")}
          </OtaGradientButton>
        </div>
      </div>

      {/* Share Section */}
      <div className="my-4 px-2">
        <ShareSection
          shareLink={window.location.href}
          customShareTitle={"Check out this article at"}
          cardTitle={t("home_category_blog_detail_page_share_section_title")}
        />
      </div>
    </div>
  );
};

export default QuizAllRanking;
